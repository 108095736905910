import * as React from 'react';
import _ from 'lodash';


import {
  SliderWrapper,
  SliderImage,
  ImageWrapper
} from './image-slide-card.style';
interface PostSlideCardProps {
  image?: any;
  className?: string;
  onClick?: () => void;
}

const ImageSlideCard: React.FunctionComponent<PostSlideCardProps> = ({
  image,
  className,
  onClick,
  ...props
}) => {
  const addClass = ['featured_card'];

  if (className) {
    addClass.push(className);
  }

  if (onClick) {
    addClass.push('hand_cursor');
  }

  return (

      <SliderWrapper className={addClass.join(' ')} onClick={onClick} {...props}>
        <ImageWrapper>
        <SliderImage src={image} alt="post preview" /> 
       </ImageWrapper>
      </SliderWrapper>
  
  );
};

export default ImageSlideCard;
