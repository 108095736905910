import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { IGatsbyImageData } from 'gatsby-plugin-image'
import Gallery from '../gallery'
import {
  Test
} from './style';


interface ImageProp {
  full: IGatsbyImageData
  thumb: IGatsbyImageData
  thumbAlt?: string
  title?: React.ReactNode
  caption?: React.ReactNode
}

type SlideShowsProps = {
  images: ImageProp[];
};

const ImageGallery: React.FunctionComponent<SlideShowsProps> = ({
  images
}) => {


  //const maxDisplay = 9;
  //images  = images.length > maxDisplay ? images.slice(0, maxDisplay) : images;


  return <Gallery mdColWidth = {100 / 3} images={images} />
};

export default ImageGallery;
