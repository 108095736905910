import styled from 'styled-components';
import Buttons from '../button/button';
import GatsbyImage from '../gatsby-image';

export const Button = styled(Buttons)`
  border-radius: 3px;
  font-size: 14px;
`;

export const PostSlideCardWrapper = styled.div`
  
`;

export const SliderImage = styled(GatsbyImage)`
height: 100%;
width: 100%;
object-fit: cover;

img {
  border-radius: 3px;
}

`

export const SliderWrapper = styled.div`

&.hand_cursor {
  cursor: pointer;
}
 
 position: relative;
 width: 100%;
 padding-bottom: 120%;
 margin: 0.25rem;
 @media (max-width: 767px) {
  padding-bottom: 100%;
}

 
`;

export const ImageWrapper = styled.div`
position: absolute;
width: 100%;
height: 100%;



`





