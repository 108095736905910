import React from "react";
import { graphql, Link } from "gatsby";
import _ from "lodash";
import urljoin from "url-join";
import Layout from "../containers/layout";
import SEO from "../components/seo";
import { getSrc } from "gatsby-plugin-image";
import PageDetails from "../components/page-details/page-details";
import useSiteMeta from "../hooks/use-site-metadata";
import ReviewSwiper from '../components/testimonial-slider';
import Newsletter from '../components/newsletter/newsletter';

import { BlogPostDetailsWrapper, BlogPostFooter } from "./templates.style";

const breakpoints:any = {
  768: {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  1024: {
    slidesPerView: 3,
    spaceBetween: 30,
  },
}

const BlogPostTemplate = ({ pageContext, ...props }: any) => {
  const page = props.data.contentfulPage;
  const image = page.image ?  getSrc(page.image) : ( page.images && page.images[0] ?  getSrc(page.images[0]) : undefined );
  const siteMeta: any = useSiteMeta();
  return (
    <Layout>
      <SEO
        title={page.title}
        image={image}
      />
      <BlogPostDetailsWrapper>
        <PageDetails
          imagePosition={"left"}
          title={page.heading || page.title}
          preview={page.image || null }
          images={page.images || null }
          body={page.body}
        />
         <ReviewSwiper page={page.slug} breakpoints={breakpoints} navigation={false}  />
         <Newsletter />
      {/*   <BlogPostFooter
          className={page.image == null ? "center" : ""}
        ></BlogPostFooter> */}
      </BlogPostDetailsWrapper>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      id
      title
      slug
      heading
      description
      body {
        raw
      }
      image {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      images {
        thumb: gatsbyImageData(
                 width: 270
                 height: 270
                 placeholder: BLURRED
                )
        full: gatsbyImageData(layout: FULL_WIDTH)  
       }
    }
  }
`;
