import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get';
export interface ImgOverlayProps {
  margin: string
}
const ImgOverlay = styled.div<ImgOverlayProps>`
font-family: 'Yantramanav', sans-serif;
color: ${themeGet('colors.white', '#fff')};
font-size: ${themeGet('fontSizes.24', '24')}px;
-webkit-user-select: none; 
-ms-user-select: none; 
user-select: none; 
position: absolute;
display: flex;
align-items: center;
justify-content: center;
z-index: 10;
background-color: rgb(39, 39, 39, 0.7 );
top: ${(props) => props.margin};
left: ${(props) => props.margin};
bottom: ${(props) => props.margin};
right: ${(props) => props.margin};
`
export default ImgOverlay
