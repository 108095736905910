import * as React from "react";
import { Link } from "gatsby";
import _ from "lodash";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import ImageGallery from '../image-gallery';
import ImageSlideCard from "../image-slide-card";
import { IGatsbyImageData, getImage } from "gatsby-plugin-image";
import {
  PostDetailsWrapper,
  PostTopTitle,
  PostSideTitle,
  PostPreview,
  PostDescriptionWrapper,
  PostDescription
} from "./post-details.style";
import VideoEmbed from "../video-embed";
import Options from "../../theme/body/options"

interface ImageProp {
  full: IGatsbyImageData;
  thumb: IGatsbyImageData;
  thumbAlt?: string;
  title?: string;
  caption?: string;
}

type PageDetailsProps = {
  title: string;
  preview?: any;
  images?: any[];
  body: any;
  className?: string;
  imagePosition?: "left" | "top";
};

const PageDetails: React.FunctionComponent<PageDetailsProps> = ({
  title,
  preview,
  images,
  body,
  className,
  imagePosition,
  ...props
}) => {

  const addClass: string[] = ["post_details"];

  if (imagePosition == "left") {
    addClass.push("image_left");
  }

  if (className) {
    addClass.push(className);
  }

  

  return (
    <PostDetailsWrapper {...props} className={addClass.join(" ")}>
      <PostTopTitle>{title}</PostTopTitle>
      <PostPreview className="post_preview">
        <div>
        { images && images.length > 1 && <ImageGallery images={images}></ImageGallery > }
        </div>
         { images && images.length === 1 &&  <ImageSlideCard
                image={images[0].gatsbyImageData}
              />}
        { !images && preview &&  <ImageSlideCard
                image={preview == null ? null : preview.gatsbyImageData}
              />} 
      </PostPreview>
      <PostDescriptionWrapper className="post_des_wrapper">
        <PostSideTitle>{title}</PostSideTitle>
        <PostDescription className="post_des">
           {body && renderRichText(body, Options)} 
        </PostDescription>
      </PostDescriptionWrapper>
    </PostDetailsWrapper>
  );
};

PageDetails.defaultProps = {
  imagePosition: "top",
};

export default PageDetails;
