import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import GatsbyImage from '../gatsby-image';

export const PostDetailsWrapper = styled.div`
  position: relative;


    @media (min-width: 769px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
    }
    .post_preview {
      margin-top: 0;
    
      @media (min-width: 769px) {
    
        width: calc(45% - 30px);
        margin-right: 60px;
   
        &:before {
          width: calc(80% - 60px);
        }
      }
    }
    .post_des_wrapper {
    
      @media (min-width: 769px) {
      
        width: calc(55% - 30px);
        margin: 0;
      }
      .post_des {
        margin-top: 60px;
      }
    }
  
`;

export const PostTitle = styled.h1`
  font-size: 40px;
  font-weight: 400;
  color: ${themeGet('colors.textColor', '#292929')};
  line-height: 1.55;
  margin-bottom: 10px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 36px;
    margin-bottom: 20px;
  }
`;


export const PostTopTitle = styled(PostTitle)`

@media (min-width: 769px) {
display: none;
}

`;

export const PostSideTitle = styled(PostTitle)`
line-height: 1;
@media (max-width: 768px) {
display: none;

}
`;

export const PostDate = styled.span`
  display: block;
  font-size: 16px;
  color: ${themeGet('colors.textLightColor', '#5e5e5e')};
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 575px) {
    font-size: 16px;
  }
`;

export const PostCategory = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  a {
    font-size: 18px;
    color: ${themeGet('primary', '#D7AB92')};
    font-weight: 400;
    text-transform: uppercase;
    &:after {
      content: ',';
    }

    &:last-child {
      &:after {
        content: '';
      }
    }
  }
  @media (max-width: 768px) {
    font-size: 13px;
  }
  @media (max-width: 575px) {
    font-size: 13px;
  }
`;

export const PostPreview = styled.div`
position: relative;
width: 100%;

`;

export const ImageWrapper = styled.div`
position: absolute;
width: 100%;
height: 100%;

max-height:800px;
`
export const SliderImage = styled(GatsbyImage)`
height: 100%;
width: 100%;
object-fit: cover;

`

export const PostDescriptionWrapper = styled.div`
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 780px;
  max-width: 100%;
  @media (max-width: 575px) {
    margin-top: 20px;
  }
`;

export const PostDescription = styled.div`
  font-size: ${themeGet('fontSizes.4', '16')}px;

  .mermaid {
    margin-bottom: 60px;
    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }

  p {
    font-size: ${themeGet('fontSizes.4', '16')}px;
    color: ${themeGet('colors.textLightColor', '#5e5e5e')};
    margin-bottom: 2em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    margin-bottom: 0.75em;
  }

  h1 {
    font-size: 30px;
    margin-bottom: 0.75em;
  }

  h2 {
    font-size: 25px;
    margin-bottom: 0.75em;
  }

  h3 {
    font-size: 21px;
  }

  h4 {
    font-size: 17px;
  }

  h5 {
    font-size: 15px;
  }

  h6 {
    font-size: 13px;
  }

  ol,
  ul {
    margin-left: 1rem;
    margin-bottom: 2rem;
    line-height: 2;
  }

  li {
    margin-bottom: 0rem;

    p {
      margin-bottom: 0.5em;
      font-weight: bold;
    }
  }

  blockquote {
    font-family: 'Poppins', sans-serif;
    font-size: 21px;
    font-weight: 500;
    line-height: 2;
    margin: 60px 0;
    @media (max-width: 1200px) {
      margin: 50px 0;
      font-size: 19px;
    }
    @media (max-width: 575px) {
      margin: 40px 0;
      font-size: 15px;
    }
    &:before,
    &:after {
      content: '';
      width: 30px;
      height: 1px;
      display: block;
      background: #292929;
    }
    &:before {
      margin-bottom: 60px;
      @media (max-width: 1200px) {
        margin-bottom: 50px;
      }
      @media (max-width: 575px) {
        margin-bottom: 40px;
      }
    }
    &:after {
      margin-top: 60px;
      @media (max-width: 1200px) {
        margin-top: 50px;
      }
      @media (max-width: 575px) {
        margin-top: 40px;
      }
    }

    p {
      font-size: 21px;
      font-weight: 500;
      line-height: 2;
      margin-bottom: 23px;
      @media (max-width: 1200px) {
        font-size: 19px;
      }
      @media (max-width: 1200px) {
        font-size: 17px;
      }
      @media (max-width: 575px) {
        font-size: 15px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    h4 {
      font-size: 16px;
      margin: 0;
      font-family: 'Fira Sans', sans-serif;
      font-weight: 400;
    }
  }

  a {
    font-weight: 500;
    transition: 0.15s ease-in-out;
    color: ${themeGet('primary', '#D7AB92')};
  }
`;

export const PostTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 60px;

  a {
    display: block;
    margin-right: 30px;
    font-size: 14px;
    font-weight: 400;
    color: ${themeGet('primary', '#D7AB92')};
  }
`;
